// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery")
require("jquery-ui")

global.$ = require("jquery")

require ('./faktura')

$.datepicker.regional['cs'] = {
  closeText: 'Zavřít',
  prevText: '&#x3c;Dříve',
  nextText: 'Později&#x3e;',
  currentText: 'Nyní',
  monthNames: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen',
    'září', 'říjen', 'listopad', 'prosinec'
  ],
  monthNamesShort: ['led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
  dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
  dayNamesShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  dayNamesMin: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  weekHeader: 'Týd',
  dateFormat: 'dd.mm.yy',
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ''
};

$.datepicker.setDefaults($.datepicker.regional['cs']);



$(document).ready(function(){
  $('.datepicker').datepicker()

})

document.addEventListener("turbolinks:load", function() {
  $('.datepicker').datepicker()
})


function mceinit() {
  if (document.getElementsByClassName('tinymce')) {


    try{
      tinyMCE.remove();
    }catch (e) {

    }

    tinyMCE.init({
      selector: "textarea.tinymce",
      theme_advanced_toolbar_location: "top",
      theme_advanced_toolbar_align: "left",
      theme_advanced_statusbar_location: "bottom",
      theme_advanced_buttons3_add: ["tablecontrols", "fullscreen"],
      plugins: "table,fullscreen",
      content_css: "/tinymce_content.css",
      language: "cs"
    });

  }else{
    console.log('t ne')
  }
}
document.addEventListener("turbolinks:load", function() {
  mceinit()


  $('#zakaznik_perioda').keyup(prepocitat);
  $('#zakaznik_mes_cena').keyup(prepocitat);
  $("#zakaznik_sleva_procent").keyup(prepocitat);
  prepocitat() ;
})  ;

function prepocitat(){
  var perioda=$('#zakaznik_perioda').val();
  var cenam=$('#zakaznik_mes_cena').val();
  var sleva=$("#zakaznik_sleva_procent").val();
  var k=1;
  if (cenam!=null && perioda!=null){

    if (sleva!=""){
      k=(1-(sleva/100));
    }

    $('#kfzaperiodu').html( perioda * cenam );
    $('#kfzaperioduss').html( perioda* cenam * k);
    $("#kfmesicne").html( cenam  );
  }


}